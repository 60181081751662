import React from 'react'
import { Link } from 'react-router-dom'
import TopImage from '../../images/why.png'
import mobileImage from '../../images/mob1.svg'
import mobileImage1 from '../../images/mob33.svg'
import resImage from '../../images/res.svg'
const WhyAvopla = () => {
  return (
    <div className="why-avopla-container">
      <div className="main-header">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-7">
              <div className="content">
                <h2 className="title">
                  Reach out our dinner network and start with avopla manager for reservations management
                </h2>
                <div className="flex-30">
                  <Link className="button" to='/registration' style={{ background: '#CA2E54' }}>REGISTER RESTAURANT</Link>
                  <Link className="button" to='/plans' style={{ background: '#3C3C44CC' }}>Explore plans</Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5">
              <div className="image-wrapper">
                <img src={TopImage} alt="" srcset="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 className="main-title text-center mb-40 color-red">
        WIN-WIN PARTNERSHIP
      </h2>
      <h2 className="subtitle text-center mb-70 color-text uppercase">
        Avopla, where every culinary getaway starts
      </h2>
      <div className="container">
        <div className="avopla-powerful-container mb-80">
          <h3 className="title uppercase">
            how AVopla can be powerful
          </h3>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3 mb-3">
              <div className="item">
                <p className="label">
                  Reach more audience
                </p>
                <p className="text">
                  Manage your table occupancy and increase the rate by reaching new diners .
                  Propose discounts for your diners and have total control how and when that offers are applied.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-3">
              <div className="item">
                <p className="label">
                  Take control on reservations
                </p>
                <p className="text">
                  Dinerss who visited your restaurant will be able to make some comments about their experience. You will have hand to reply on their comments and
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-3">
              <div className="item">
                <p className="label">
                  Be close to your guests
                </p>
                <p className="text">
                  Diners who visited your restaurant will be able to make some comments about their experience. You will have hand to reply on their comments and
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-3">
              <div className="item">
                <p className="label">
                  Limit the no show
                </p>
                <p className="text">
                  Our easy-use application make all reservations cancellation easy and instant for the diners. We also give you the hand to remind your diners on their upcoming reservations.
                  We work together with diners to reduce the no-show rates
                </p>
              </div>
            </div>
          </div>

        </div>

      </div>
      <div className="container">
        <div className="image-content-section mb-80">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="image-section left">
                <img src={mobileImage} />
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="content-section">
                <h2 className="title uppercase">
                  REACH out more foodies
                </h2>
                <div className="row">
                  <div className="col-12 col-md-6 mb-4">
                    <div className="item">
                      <p className="label">
                        Reach larger audience
                      </p>
                      <p className="text">
                        Be part of large table reservations  spotlight and reach our large dinning network. Customize your restaurant page  with your photos, menus, reviews, and reservation calendar with bookable dates and times.

                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-4">
                    <div className="item">
                      <p className="label">
                        Use promotional offers
                      </p>
                      <p className="text">
                        Propose discounts for your diners and have total control on how and when that offers are applied.
                        Making offers on the off-pick periods will help you attract new diners and increase your occupancy rates


                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-4">
                    <div className="item">
                      <p className="label">
                        What diners need to know
                      </p>
                      <p className="text">
                        Because every detail count, the restaurant details page will display all guests need to know before making their choice mainly when information is clear and easy to find.


                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-4">
                    <div className="item">
                      <p className="label">
                        Be more visible
                      </p>
                      <p className="text">
                        By joining Avopla, you increase your visibiliy targetiing a large dinning network.
                        Your restaurant will be available for reservations  24/7.



                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="image-content-section mb-80">
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="content-section">
                <h2 className="title uppercase">
                  customer Experience
                </h2>
                <div className="row">
                  <div className="col-12 col-md-6 mb-4">
                    <div className="item">
                      <p className="label">
                        Reviews full visibilty
                      </p>
                      <p className="text">
                        Get a permanent view on your diners reviews. Be sure, only guests who have honored their reservation can review and rate their experience.
                        Take a permanent look to your overall rating score, list all reservations reviews, check comments and reviews photos.

                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-4">
                    <div className="item">
                      <p className="label">
                        Respond to feedbacks
                      </p>
                      <p className="text">
                        Be close to your diners by replying their comments. Either to thank them for enjoying meal at your restaurant or to understand their considerations. Replying to a comment is always the best way to be close to your guests.


                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-4">
                    <div className="item">
                      <p className="label">
                        Increase influence
                      </p>
                      <p className="text">
                        A good comment system with replying options increase diners trust. Every happy diners will make your restaurant shining.


                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-4">
                    <div className="item">
                      <p className="label">
                        Automatic survey sending
                      </p>
                      <p className="text">
                        We take care of sending an automatic survey after each honored reservation. We give the opportunity for every diner to give his feedback using an easy and clear form, publish it after a careful check.



                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="image-section right">
                <img src={mobileImage1} />
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="container">
        <div className="image-content-section mb-80">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="image-section contain left">
                <img src={resImage} />
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="content-section">
                <h2 className="title uppercase">
                  Have control on reservations
                </h2>
                <div className="row">
                  <div className="col-12 col-md-6 mb-4">
                    <div className="item">
                      <p className="label">
                        List all reservations
                      </p>
                      <p className="text">
                        We make things easier for you as restaurant manager by listing your upcoming and past reservations. For each, every single detail as diners special request will help you making good decisions.

                      </p>
                    </div>
                  </div>
                 
                  <div className="col-12 col-md-6 mb-4">
                    <div className="item">
                      <p className="label">
                        Limit the no-show
                      </p>
                      <p className="text">
                      Reminders can be sent to diners for upcoming reservations, that helps guests to check the reservation details and cancel in case they cannot honor their presence. 
                      Users’ statistics at your restaurant will help you taking the right decision by identifying potential no-showers
 

                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-4">
                    <div className="item">
                      <p className="label">
                        Manage your dinning room
                      </p>
                      <p className="text">
                        Our seating plan is the complete way to organise all reservations. By assigning tables to each reseravtion, we make sure to help you running services smartly and smoothly.
                        You can view in real-time your table occupations and improve the way it is occupied.



                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-4">
                    <div className="item">
                      <p className="label">
                        Take control on each reservation
                      </p>
                      <p className="text">
                        When necessary, restaurant can move reservation with diners agreement. Restaurant can choose to maintain the approved offer or not.
                        For each timeslot, restaurant can check all availabilities and make sure he has enough seats for any changes.
                        Never been easier to take action as rejecting reservations in case of any service interruption or simply announce the non presence of a diners.


                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
      <div className="footer-top">
        <div className="container">
          <h2 className="title uppercase text-center">
          Ready to be part of our dinning tables 
          </h2>
          <h3 className="subtitle text-center">
          The easy way to promote you restaurant and increase your occupancy rate
          </h3>
          <div className="d-flex justify-content-center">
            <Link className='button' to='/registration'>REGISTER RESTAURANT</Link>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default WhyAvopla