import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import TopImage from '../../images/plans.svg'
import { Services } from '../../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Registration = () => {
  const [loading,setLoading]=useState('')
  const [firstName,setFirstName]=useState('')
  const [lastName,setLastName]=useState('')
  const [email,setEmail]=useState('')
  const [phone,setPhone]=useState('')
  const [bestwaytocontact,setBestwaytocontact]=useState('')
  const [ownerormanager,setOwnerormanager]=useState('')
  const [businessPlan,setBusinessPlan]=useState('')
  const [restaurantName,setRestaurantName]=useState('')
  const [purposeOfContact,setPurposeOfContact]=useState('')
  const [restaurantAddress,setRestaurantAddress]=useState('')
  const [tableExpectation,settableExpectation]=useState('')
  const handlePlanChange = (event) => {
    setBusinessPlan(event.target.id); 
  };
  const registerFunction = async (data) => {
    let formData = new FormData()
    if(firstName){
      formData.append('first_name',firstName)
    }
    if(lastName){
      formData.append('last_name',lastName)
    }
    if(email){
      formData.append('email',email)
    }
    if(phone){
      formData.append('phone',phone)
    }
    if(bestwaytocontact){
      formData.append('best_way_to_contact',bestwaytocontact)
    }
    if(ownerormanager){
      formData.append('is_owner_or_manager',ownerormanager)
    }
    if(businessPlan){
      formData.append('fitting_business_plan',businessPlan)
    }
    if(restaurantName){
      formData.append('restaurant_name',restaurantName)
    }
    if(restaurantAddress){
      formData.append('restaurant_address',restaurantAddress)
    }
    if(purposeOfContact){
      formData.append('purpose_of_contact_us',purposeOfContact)
    }
    if(tableExpectation){
      formData.append('online_enable_table_expectation',tableExpectation)
    }
    setLoading(true)
try {
  const response =  await Services.registrationApi(formData);
  setLoading(false)
    
  
  // console.log(response);
  if(response.data.success === true){
    setFirstName('')
    setLastName('')
    setEmail('')
    setPhone('')
    setBusinessPlan('')
    setBestwaytocontact('')
    setOwnerormanager('')
    setRestaurantAddress('')
    setRestaurantName('')
    setPurposeOfContact('')
    settableExpectation('')
    
      toast.success("email sent succesfuly",{
          autoClose:1500,
          pauseOnHover:true,
          draggable:true,
          
        });
       
  }
  
  else{
    setLoading(false)
   
    toast.error(response?.data?.errors[0],{
      autoClose:2000,
      pauseOnHover:true,
      draggable:true,
      
    });
  }
  
} catch (error) {
  setLoading(false)

  
  console.log('rrr',error)
  toast.error("some information missing",{
    autoClose:2000,
    pauseOnHover:true,
    draggable:true,
    
  });
}

 

}

  return (
    <div className="why-avopla-container">
      <ToastContainer />
      {loading && <div className="parent-loader">
        <div className="_loader"></div></div>}
    <div className="main-header mb-40">
    <div className="container">
        <div className="contact-wrapper">
          <h2 className="title">
          Tell us about your restaurant
          </h2>
          <p className="subtitle">
          We will be happy to discuss with you about your business needs. 
          </p>
          <p className="title-small">
          contact details
          </p>
          <div className="row mb-4">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">First name <sup>*</sup></label>
                <input type="text" value={firstName} onChange={(e)=>setFirstName(e.target.value)} placeholder='First name *' className="input" />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">Last name <sup>*</sup></label>
                <input type="text" value={lastName} onChange={(e)=>setLastName(e.target.value)} placeholder='Last name *' className="input" />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">Your email address <sup>*</sup></label>
                <input type="text" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='Your email address *' className="input" />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">Your phone number <sup>*</sup></label>
                <input type="text" value={phone} onChange={(e)=>setPhone(e.target.value)} placeholder='Your phone number *' className="input" />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">best way to contact you </label>
                <select name="" value={bestwaytocontact} onChange={(e)=>setBestwaytocontact(e.target.value)} id="" className="input">
                <option value="">Select</option>
                  <option value="By Email">By Email</option>
                  <option value="By Phone">By Phone</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">Do you own or manage the restaurant? </label>
                <select name="" value={ownerormanager} onChange={(e)=>setOwnerormanager(e.target.value)} id="" className="input">
                <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>
            
          </div>
          <div className="custom-radio-wrapper">
            <span className="title">
            Wich plan do you think fitting closer to your business needs? 
            </span>
            <div className="right">
              <div className="custom-radio">
                <input type="radio" onChange={handlePlanChange}
          checked={businessPlan == 'basic'} name="plan" id="basic" />
                <label htmlFor="basic">Basic plan</label>
              </div>
              <div className="custom-radio">
                <input type="radio" onChange={handlePlanChange}
          checked={businessPlan === 'premium'} name="plan" id="premium" />
                <label htmlFor="premium">Premium plan</label>
              </div>
            </div>

          </div>
          <p className="title-small">
          Restaurant details
          </p>
          <div className="row mb-4">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">your restaurant name <sup>*</sup></label>
                <input type="text" value={restaurantName} onChange={(e)=>setRestaurantName(e.target.value)} placeholder='your restaurant name *' className="input" />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">your restaurant address <sup>*</sup></label>
                <input type="text" value={restaurantAddress} onChange={(e)=>setRestaurantAddress(e.target.value)} placeholder='your restaurant address *' className="input" />
              </div>
            </div>
            
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">The purpose of contacting us </label>
                <select name="" value={purposeOfContact} onChange={(e)=>setPurposeOfContact(e.target.value)} id="" className="input">
                <option value="">Select</option>
                  <option value="I want to learn about Avopla">I want to learn about Avopla</option>
                  <option value="I want to schedule a demo">I want to schedule a demo</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">How many tables you expect to enable online </label>
                <select name="" value={tableExpectation} onChange={(e)=>settableExpectation(e.target.value)} id="" className="input">
                  <option value="">Select</option>
                  <option value="5-10">5-10</option>
                  <option value="11-20">11-20</option>
                  <option value="21 +">21 +</option>
                </select>
              </div>
            </div>
            
          </div>
          <div className="d-flex justify-content-end">
            <button className="button" onClick={registerFunction}>
              Submit
            </button>
          </div>
        </div>
      </div>
      </div>
      
      
    </div>
  )
}

export default Registration