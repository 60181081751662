import React from 'react'
import { Link } from 'react-router-dom'
import footerLogo from '../../src/images/footer-logo.svg'

const Footer = () => {
  return (
    <div className='main-footer'>
     <div className="container">
     <div className="top">
        <div className="left">
          <Link className='link' target='_blank' to='https://www.manager.avopla.ca/'>
          Sign In
          </Link>
          <Link className='link' target='_blank' to='https://avopla.com/legal/privacy-policy'>
          Privacy policy
          </Link>
          <Link className='link' target='_blank' to='https://avopla.com/legal/terms-and-conditions'>
          Terms of use
          </Link>
          <Link to='/plans' className='link'>
          Our plans
          </Link>
        </div>
        <Link to='/registration' className="button">
        Contact us
        </Link>
      </div>
      <div className="copy">
      <img src={footerLogo} alt="" />
  <div className="text">Copyright 2025 Avopla, Inc </div>
      </div>
     </div>
    </div>
  )
}

export default Footer